<template>
  <div>
    <Header :instanceInfo="instanceInfo" />
    <div class="main">
      <div class="container">
        <h2 class="pink">Buy Credits</h2>
      </div><!--/container-->
    </div>
    <form v-if="isPremium" @submit.prevent="submitTopUp">
      <div class="payment-mod-gold">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-3"></div>
            <div class="col-md-6">
              <h3 align="center">Choose the amount of credits you want</h3>
              <ul class="list-credits2 paymethod_select">
                <li v-for="bundle in instanceInfo.bundles" :key="bundle.credits" class="product_item">
                  <input v-model="credits" :id="'credits_' + bundle.credits" name="product_id" type="radio"
                    :value="bundle.credits">
                  <label :for="'credits_' + bundle.credits">
                    <p class="p0 p10">{{ bundle.credits }} Credits</p>
                    <p class="p11"></p>
                    <p class="p12">{{ bundle.tariff }} {{ instanceInfo.subscriptionPlan.currency }}</p>
                  </label>
                </li>
              </ul>
              <button class="btn-submit-pay" type="submit">Buy Now!</button>
              <div class="desc">
                <p>We respect the privacy of our users. <br>Any charges made on your credit card will appear under:</p>
                <p>
                  <img src="/theme/001/images/tick3.png" width="15">
                  {{ instanceInfo.supportDomain }}
                </p>
                <p>By making a purchase on this site, you agree to our <router-link to="/terms">Terms and
                    Conditions</router-link>.</p>
                <p>
                  <a :href="'mailto:' + instanceInfo.supportEmail" target="_blank">Billing support</a> | <router-link
                    to="/contact">Other support</router-link>
                </p>
                <p>
                  <img src="/global/img/mastercard.png" alt="Mastercard"> <img src="/global/img/visa2.png" alt="VISA">
                </p>
              </div>
            </div>


            <hr>

            <div class="col-md-2"></div>
            <div class="col-md-8">
              <h2 align="center" style="text-transform:uppercase;">Need help?</h2>
              <h3 align="center">Send us an email: <a :href="'mailto:' + instanceInfo.supportEmail">{{
                instanceInfo.supportEmail }}</a></h3>
            </div>

            <div class="col-md-2"></div>

          </div>
        </div>
      </div><!--/payment-mod-gold-->

    </form>
    <div v-else class="container">
      <div class="col-md-12">
        <br/>
        <p class="alert alert-danger">You need a subscription to be able to buy credits.
        </p>
        <router-link to="/credits/subscription" class="btn btn-default">
          Buy trial
        </router-link>
        <br/>
        <br/>
      </div>
    </div>
    <Footer :instance-info="instanceInfo" :company-name="companyName" v-bind="$attrs" />
  </div>
</template>

<script>
import { ref, toRef, watch } from 'vue';
import Header from '@/app/components/Header';
import Footer from '@/app/components/Footer';
import { makeTopUpPayment } from '@/app/api/user';
import { createAndSendForm, handlePrecondition } from '@/app/use/payment';
import { redirectToRoute } from '@/app/router';
import { useUser } from '@/app/use/user';

export default {
  name: 'TopUp',
  components: { Header, Footer },
  props: {
    instanceInfo: {
      type: Object,
      required: false,
    },
    companyName: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const { isPremium } = useUser();
    const subscriptionPlan = toRef(props.instanceInfo, 'subscriptionPlan');
    const currentCurrency = ref(subscriptionPlan.value.currency);

    watch(
      () => props.instanceInfo,
      async () => {
        currentCurrency.value = props.instanceInfo.subscriptionPlan.currency;
      },
      { immediate: true, deep: true }
    );

    const credits = ref('10');

    const isPaying = ref(false);

    const submitTopUp = () => {
      isPaying.value = true;
      const topUpRequest = {
        currency: currentCurrency.value,
        credits: credits.value,
        browser: {
          language: navigator.language,
          screenHeight: screen.height,
          screenWidth: screen.width,
          screenColorDepth: screen.colorDepth,
          timezone: new Date().getTimezoneOffset(),
          userAgent: navigator.userAgent,
          javaEnabled: false,
          javaScriptEnabled: true,
        }
      };
      makeTopUpPayment(topUpRequest)
        .then(response => {
          handleResponse(response);
        })
        .catch(() => {
          redirectToRoute('/credits/failure');
        })
        .finally(() => (isPaying.value = false));
    }

    const handlePendingResponse = (response) => {
      const threeDSResponse = response.threeDSResponse;
      const threeDUrl = threeDSResponse.url;
      const preconditions = threeDSResponse.preconditions;
      const threeDUrlParams = threeDSResponse.params;
      if (threeDUrl != null && preconditions && preconditions.length) {
        Promise.all(
          preconditions.map((precondition, index) =>
            handlePrecondition({
              index,
              ...precondition
            }),
          ),
        )
          .then(() => {
            createAndSendForm(threeDUrl, threeDUrlParams);
          })
          .catch(() => {
            redirectToRoute('/credits/failure');
          });
      } else if (threeDUrl != null) {
        createAndSendForm(threeDUrl, threeDUrlParams);
      } else {
        redirectToRoute('/credits/failure');
      }
    };

    const handleResponse = (response) => {
      if (response.status === 'SUCCESSFUL') {
        redirectToRoute('/credits/success');
      } else if (response.status === 'PENDING') {
        handlePendingResponse(response);
      } else {
        redirectToRoute('/credits/failure');
      }
    };

    return { isPremium, credits, currentCurrency, submitTopUp };
  },
};
</script>
